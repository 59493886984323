import IconButton from "@material-ui/core/IconButton";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import classNames from "classnames";
import dayjs, { Dayjs as DayjsType } from "dayjs";
import React from "react";

import { useDatePickerRange } from "~/components/core/datepickers/CustomRangePicker/useDatePickerRange";
import { DATE_UNIT, DAY_FORMAT } from "~/constants/dates";

export interface Props {
  onClick: (date: DayjsType) => void;
  onFocus: () => void;
  date: MaterialUiPickersDate;
  beginDate: MaterialUiPickersDate;
  endDate: MaterialUiPickersDate;
  hoveredDate: MaterialUiPickersDate;
  dayInCurrentMonth: boolean;
  disableFuture?: boolean;
}

const CustomRangePickerDay: React.FC<Props> = ({
  date,
  dayInCurrentMonth,
  onClick,
  onFocus,
  beginDate,
  endDate,
  hoveredDate,
  disableFuture
}: Props): JSX.Element | null => {
  const {
    frontier,
    inHoveredRange,
    inSelectedRange,
    // current,
    frontierStart,
    frontierEnd
  } = useDatePickerRange({
    date,
    beginDate,
    endDate,
    hoveredDate
  });

  if (!date) return null;

  const handleSelectDay = (): void => onClick(date);

  const futureDay = disableFuture && date.isAfter(dayjs());
  const selected = (inSelectedRange || inHoveredRange) && !futureDay;

  return (
    <div
      className={classNames(
        "relative h-[40px] w-[48px] my-[4px] flex items-center justify-center",
        dayInCurrentMonth &&
          selected &&
          !(frontierStart || frontierEnd) &&
          "bg-chalkboard-200"
      )}
    >
      {(frontierStart || frontierEnd) &&
        dayInCurrentMonth &&
        !(
          endDate &&
          beginDate &&
          dayjs(endDate).isSame(dayjs(beginDate), DATE_UNIT.day)
        ) && (
          <span
            className={classNames(
              "absolute r-0 top-0 w-[20px] h-[40px] px-[4px] bg-chalkboard-200",
              frontierStart ? "right-0" : "left-0"
            )}
          />
        )}
      <IconButton
        // current={current ? CURRENT : ""}
        // selected={selected ? SELECTED : ""}
        // faded={selected && !dayInCurrentMonth ? FADED : ""}
        // active={dayInCurrentMonth && !futureDay ? ACTIVE : ""}
        // frontier={frontier && dayInCurrentMonth ? FRONTIER : ""}
        onClick={handleSelectDay}
        onMouseOver={onFocus}
        disableRipple
        className={classNames(
          "w-[40px] h-[40px] paragraph rounded-none hover:!bg-transparent",
          frontier &&
            dayInCurrentMonth &&
            "!text-white bg-chalkboard-700 !rounded-full hover:!bg-chalkboard-700",
          !(dayInCurrentMonth && !futureDay) && "invisible"
        )}
        classes={{
          label:
            "min-w-[40px] w-[40px] px-[12px] h-[40px] flex items-center justify-center rounded-full hover:ring-navy-900 ring-1 ring-transparent"
        }}
        disabled={!(dayInCurrentMonth && !futureDay)}
      >
        {dayjs(date).format(DAY_FORMAT)}
      </IconButton>
    </div>
  );
};

export default CustomRangePickerDay;

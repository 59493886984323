import DayjsUtils from "@date-io/dayjs";
import {
  addDays,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  isBefore,
  startOfMonth,
  startOfWeek
} from "date-fns";
import dayjs from "dayjs";

// import { getCachedWeekStart } from "~/utils/cachedWeekStart";

export class WeekStartAwareDayjsUtils extends DayjsUtils {
  public getWeekdays(): string[] {
    // const weekStartsOn = getCachedWeekStart();
    const now = new Date();
    return eachDayOfInterval({
      start: startOfWeek(now, { weekStartsOn: 0 }),
      end: endOfWeek(now, { weekStartsOn: 0 })
    }).map(day => format(day, "EEEEEE", { weekStartsOn: 0 }));
  }

  public getWeekArray(day: dayjs.Dayjs): dayjs.Dayjs[][] {
    // const weekStartsOn = getCachedWeekStart();
    const start = startOfWeek(startOfMonth(day.toDate()), { weekStartsOn: 0 });
    const end = endOfWeek(endOfMonth(day.toDate()), { weekStartsOn: 0 });

    let count = 0;
    let current = start;
    const nestedWeeks: Date[][] = [];

    while (isBefore(current, end)) {
      const weekNumber = Math.floor(count / 7);
      nestedWeeks[weekNumber] = nestedWeeks[weekNumber] || [];
      nestedWeeks[weekNumber].push(current);
      current = addDays(current, 1);
      count += 1;
    }

    return nestedWeeks.map(row => row.map(date => dayjs(date)));
  }
}

import styled from "@emotion/styled";
import { Calendar } from "@material-ui/pickers";
import { Dayjs as DayjsType } from "dayjs";
import * as React from "react";

import { RenderDayFunc } from "~/declarations/mui-datepickers";

export interface Props {
  renderDay: RenderDayFunc;
  date: DayjsType;
}

const DatePickerContent: React.FC<Props> = ({
  renderDay,
  date
}: Props): JSX.Element => {
  return (
    <CalendarWrapper>
      <Calendar
        date={date}
        currentMonth={date}
        renderDay={renderDay}
        slideDirection="left"
        wrapperVariant="static"
        reduceAnimations={false}
        onChange={(): void => void 0}
      />
    </CalendarWrapper>
  );
};

export default DatePickerContent;

const CalendarWrapper = styled.div`
  > div:first-of-type {
    > span {
      width: 48px;
      height: 48px;
      font-weight: 600;
      font-family: "TT Commons";
      font-size: 15px;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.45px;
      text-transform: uppercase;
      color: #151d34;
      margin: 0;
    }
  }

  > div:last-child {
    min-height: 290px;
  }
`;

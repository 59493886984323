import * as React from "react";

import FilterToolbarAdaptiveContent from "~/components/core/FilterToolbarAdaptiveContent";
import { PERIOD, PERIODS } from "~/constants/dates";
import { RANGE_GETTERS } from "~/constants/filters";
import { Period, Range } from "~/declarations/filters";

import { SelectDates } from "./SelectDates";

const periodOptions = PERIODS.map(period => ({
  value: period.period,
  label: period.title
}));

interface Props {
  selectedTab?: PERIOD;
  onChange?: (period: Period, range: Range) => void;
  children?: React.ReactNode;
  className?: string;
  rangeFilter?: Range;
  isDashboard?: boolean;
  CustomRangePicker?: React.FC<{
    onSubmit: (range: Range) => void;
    initialRange?: Range;
    hint?: string;
    open: boolean;
    anchorEl: Element | null;
    onClose: () => void;
  }>;
}

const FilterToolbar = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      onChange,
      selectedTab,
      className,
      children,
      rangeFilter,
      isDashboard
    }: Props,
    ref
  ) => {
    const handleSelectPeriod = (period: string) => {
      const newPeriod = PERIODS.find(x => x.period === period);
      if (newPeriod && newPeriod.period !== PERIOD.custom) {
        onChange?.(newPeriod, RANGE_GETTERS[newPeriod.period]());
      }
    };

    const handleChangeCustomPeriod = (range: Range) => {
      onChange?.({ period: PERIOD.custom, title: "Custom" }, range);
    };

    const selectedOption = selectedTab
      ? PERIODS.find(period => period.period === selectedTab)
      : undefined;

    return (
      <FilterToolbarAdaptiveContent
        className={className}
        ref={ref}
        isDashboard={isDashboard}
      >
        {selectedOption && (
          <SelectDates
            selectedOption={{
              value: selectedOption.period,
              label: selectedOption.title
            }}
            options={periodOptions}
            onChange={handleSelectPeriod}
            handleChangeCustomPeriod={handleChangeCustomPeriod}
            initialRange={rangeFilter ?? [null, null]}
            rangeFilter={rangeFilter}
          />
        )}
        {children}
      </FilterToolbarAdaptiveContent>
    );
  }
);

export default FilterToolbar;
